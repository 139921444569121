var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view" },
    [
      _c("header-section", {
        attrs: {
          heading: "Trending Charts",
          subheading: "Your daily update of the hottest beats in TheCharts.",
          img: "bg-trending",
        },
      }),
      _c("section", { staticClass: "section" }, [
        _vm.isLoading
          ? _c("div", { staticClass: "container" }, [_c("VLoadSpinner")], 1)
          : _c(
              "div",
              { staticClass: "container" },
              [
                _c("TrendingFilterList", {
                  attrs: { inTrendingPage: true },
                  on: { changeTrendingFilter: _vm.fetchTrendingWithFilter },
                }),
                _c("BeatTable", {
                  attrs: {
                    parentState: _vm.ParentStateType.Trending,
                    beatPlaylist: _vm.trendingBeatsWithPromoted,
                    amountOfPromotedBeats: _vm.promotedBeatsTrending.length,
                  },
                }),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }