













import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import BeatTable from '@/components/BeatTable.vue'
import HeaderSection from '@/components/HeaderSection.vue'
import TrendingFilterList from '@/components/TrendingFilterList.vue'
import { fetchTrendingBeats, TrendingPeriod } from '@/api/beats'
import { ParentStateType } from '~/const/parent-state-type'
import { fetchPromotedBeats } from '@/api/promotion'

@Component({ components: { VLoadSpinner, BeatTable, HeaderSection, TrendingFilterList } })
export default class TrendingPage extends Vue {
    trendingBeats: IBeat[] = []
    promotedBeatsTrending: IBeat[] = []
    isLoading = true
    ParentStateType = ParentStateType

    get trendingBeatsWithPromoted() {
        return [...this.promotedBeatsTrending, ...this.trendingBeats]
    }

    async fetchTrendingBeats() {
        this.trendingBeats = await fetchTrendingBeats(100, TrendingPeriod.AllTime, null)
        this.promotedBeatsTrending = await fetchPromotedBeats('Trending')
        this.isLoading = false
    }

    async fetchTrendingWithFilter(limit: number, trendingFilterPeriod: TrendingPeriod, trendingFilterGenreId: IGenre['id']) {
        this.trendingBeats = await fetchTrendingBeats(limit, trendingFilterPeriod, trendingFilterGenreId)
    }

    mounted() {
        this.fetchTrendingBeats()
    }
}
